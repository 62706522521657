import Footer from "components/Footer/Footer";
import Header from "components/Header";
import React, { Suspense, useState, useRef, useEffect, Fragment } from "react";
import { Img, Text, Heading, Button } from "../../components";

import { Helmet } from "react-helmet";
import EventSlider from "components/EventSlider/EventSlider";

export default function Event() {
  const videoRef1 = useRef(null); // Reference to the video element
  const videoRef2 = useRef(null); // Reference to the video element
  const videoRef3 = useRef(null); // Reference to the video element
  const [isPlaying, setIsPlaying] = useState({
    video1: true,
    video2: true,
    video3: true,
  }); // State to manage play/pause
  const isiPhone = /iPhone/.test(navigator.userAgent);
  const [isHovered, setIsHovered] = useState(false);
  const [imgFileName, setImgFileName] = useState([]);

  const handleRegsiter = () => {
    let url = "https://register.rie2025.com";
    window.open(url, "_blank", "noreferrer");
    // setModalComingIsOpen(true);
  };
  const handlePlayPause = (videoKey, videoRef) => {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
      setIsPlaying({ ...isPlaying, [videoKey]: true });
    } else {
      video.pause();
      setIsPlaying({ ...isPlaying, [videoKey]: false });
    }
  };
  const toggleHover = (ind, hover = false) => {
    setImgFileName((prev) =>
      prev.map((c, i) =>
        i === ind ? (hover ? `essance-${i + 1}-hover` : `essance-${i + 1}`) : c
      )
    );
  };

  useEffect(() => {
    let arr = [];
    for (let i = 0; i < 10; i++) {
      arr.push(`essance-${i + 1}`);
    }
    setImgFileName(arr);
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Plan Your Trip - Celebrating EO Bangalore&#39;s 25-Year Legacy
        </title>
        <meta
          name="description"
          content="Discover the essence of RIE, a curated 3-day event by EO Bangalore that blends world-class learning and networking with a rich cultural showcase. RIEMIX 2025 marks a remix of tradition and innovation."
        />
      </Helmet>

      <div className="flex justify-center self-stretch bg-black-900_7f py-[60px] lg:py-8 md:py-5 sm:py-4 video-container vdo-home ratio ratio-16x9">
        <div className="max-w-[1702px] mx-auto flex w-full flex-col gap-32 self-stretch lg:gap-24 md:gap-24 md:p-5 sm:gap-16 py-144">
          <div className="flex w-[50%] flex-col items-center justify-end  lg:w-full md:w-full">
            <div className="">
              <video
                width="100%"
                className="video-background"
                ref={videoRef1}
                autoPlay
                playsInline
                loop
                muted
              >
                <source
                  src={`${process.env.PUBLIC_URL}/video/about-rie.mp4`}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>

              <div className="videoDescp content">
                {isiPhone && (
                  <button
                    id="myBtn"
                    className="mt-[48px] lg:mt-[148px] md:mt-[78px]"
                    onClick={() => handlePlayPause("video1", videoRef1)}
                  >
                    {/* {isPlaying ? "Pause" : "Play"} */}
                    {/* <Img
                        src="images/img_frame_icon.svg"
                        alt="event image"
                        className="h-[234px] w-[100%] md:h-[100px]"
                      /> */}
                    <Img
                      src={`${process.env.PUBLIC_URL}/images/play.png`}
                      alt="play"
                      className="h-[50px] w-auto md:h-[50px]"
                    />
                    {/* <img  src={`${process.env.PUBLIC_URL}/images/play.png`} /> */}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="p-10 md:p-5">
        <span className="halfCricleLineTwo  ">
          <svg
            width="518"
            height="1035"
            viewBox="0 0 518 1035"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              class="line"
              d="M518 1034.43C232.6 1034.43 1.23694 803.071 1.23694 517.671C1.23694 232.271 232.6 0.908203 518 0.908203"
              stroke="#ECC846"
            />
            <path
              class="line"
              d="M518 946.147C281.359 946.147 89.5239 754.312 89.5239 517.671C89.5239 281.03 281.359 89.1953 518 89.1953"
              stroke="#ECC846"
            />
          </svg>
        </span>

        <span className="arrowDownLeftRed">
          <svg
            width="151"
            height="857"
            viewBox="0 0 151 357"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              class="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M34.4683 48.3972L66.3947 1.78796H2.54199L34.4683 48.3972Z"
              stroke="#29A98C"
              stroke-width="2"
            />
            <path
              class="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M34.4683 135.083L66.3947 88.4739H2.54199L34.4683 135.083Z"
              stroke="#29A98C"
              stroke-width="2"
            />
            <path
              class="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M34.4683 221.769L66.3947 175.16H2.54199L34.4683 221.769Z"
              stroke="#29A98C"
              stroke-width="2"
            />
            <path
              class="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M116.187 95.0061L148.114 48.397H84.261L116.187 95.0061Z"
              stroke="#29A98C"
              stroke-width="2"
            />
            <path
              class="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M116.187 181.692L148.114 135.083H84.261L116.187 181.692Z"
              stroke="#29A98C"
              stroke-width="2"
            />
            <path
              class="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M116.187 268.378L148.114 221.769H84.261L116.187 268.378Z"
              stroke="#29A98C"
              stroke-width="2"
            />
            <path
              class="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M34.4683 308.455L66.3947 261.846H2.54199L34.4683 308.455Z"
              stroke="#29A98C"
              stroke-width="2"
            />
            <path
              class="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M116.187 355.064L148.114 308.455H84.261L116.187 355.064Z"
              stroke="#29A98C"
              stroke-width="2"
            />
          </svg>
        </span>

        <span className="arrowDownLeftRed bottom">
          <svg
            width="131"
            height="883"
            viewBox="0 0 131 383"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="path2"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M29.6621 189.853L1.95898 230.297H57.3653L29.6621 189.853Z"
              stroke="#ED3B70"
              stroke-width="2"
            />
            <path
              className="path2"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M29.6621 114.142L1.95898 154.586H57.3653L29.6621 114.142Z"
              stroke="#ED3B70"
              stroke-width="2"
            />
            <path
              className="path2"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M29.6621 38.4312L1.95898 78.875H57.3653L29.6621 38.4312Z"
              stroke="#ED3B70"
              stroke-width="2"
            />
            <path
              className="path2"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M101.406 153.195L73.7029 193.639H129.109L101.406 153.195Z"
              stroke="#ED3B70"
              stroke-width="2"
            />
            <path
              className="path2"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M101.406 77.4839L73.7029 117.928H129.109L101.406 77.4839Z"
              stroke="#ED3B70"
              stroke-width="2"
            />
            <path
              className="path2"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M101.406 1.77295L73.7029 42.2168H129.109L101.406 1.77295Z"
              stroke="#ED3B70"
              stroke-width="2"
            />
            <path
              className="path2"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M29.6621 341.275L1.95898 381.718L57.3653 381.719L29.6621 341.275Z"
              stroke="#ED3B70"
              stroke-width="2"
            />
            <path
              className="path2"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M29.6621 265.564L1.95898 306.008H57.3653L29.6621 265.564Z"
              stroke="#ED3B70"
              stroke-width="2"
            />
            <path
              className="path2"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M101.405 304.617L73.7019 345.061H129.108L101.405 304.617Z"
              stroke="#ED3B70"
              stroke-width="2"
            />
            <path
              className="path2"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M101.405 228.906L73.7019 269.349H129.108L101.405 228.906Z"
              stroke="#ED3B70"
              stroke-width="2"
            />
          </svg>
        </span>

        <span className="arrowDownLeftRed right">
          <svg
            width="150"
            height="358"
            viewBox="0 0 150 358"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M33.9593 48.5962L65.8856 1.98706H2.03296L33.9593 48.5962Z"
              stroke="#ECC846"
              stroke-width="2"
            />
            <path
              className="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M33.9593 132.016L65.8856 85.407H2.03296L33.9593 132.016Z"
              stroke="#ECC846"
              stroke-width="2"
            />
            <path
              className="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M33.9593 215.436L65.8856 168.827H2.03296L33.9593 215.436Z"
              stroke="#ECC846"
              stroke-width="2"
            />
            <path
              className="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M33.9593 308.654L65.8856 262.045H2.03296L33.9593 308.654Z"
              stroke="#ECC846"
              stroke-width="2"
            />
            <path
              className="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M115.678 95.2051L147.605 48.5959H83.752L115.678 95.2051Z"
              stroke="#ECC846"
              stroke-width="2"
            />
            <path
              className="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M115.678 178.625L147.605 132.016H83.752L115.678 178.625Z"
              stroke="#ECC846"
              stroke-width="2"
            />
            <path
              className="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M115.678 262.045L147.605 215.436H83.752L115.678 262.045Z"
              stroke="#ECC846"
              stroke-width="2"
            />
            <path
              className="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M115.678 355.264L147.605 308.655H83.752L115.678 355.264Z"
              stroke="#ECC846"
              stroke-width="2"
            />
          </svg>
        </span>

        <span className="arrowDownLeftRed right two">
          <svg
            width="132"
            height="384"
            viewBox="0 0 132 384"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M101.642 193.564L129.345 153.12H73.9387L101.642 193.564Z"
              stroke="#2AA98B"
              stroke-width="2"
            />
            <path
              className="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M101.642 269.275L129.345 228.831H73.9387L101.642 269.275Z"
              stroke="#2AA98B"
              stroke-width="2"
            />
            <path
              className="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M101.642 344.985L129.345 304.542H73.9387L101.642 344.985Z"
              stroke="#2AA98B"
              stroke-width="2"
            />
            <path
              className="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M29.8989 230.222L57.6021 189.778H2.1958L29.8989 230.222Z"
              stroke="#2AA98B"
              stroke-width="2"
            />
            <path
              className="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M29.8989 305.933L57.6021 265.489H2.1958L29.8989 305.933Z"
              stroke="#2AA98B"
              stroke-width="2"
            />
            <path
              className="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M29.8989 381.644L57.6021 341.2H2.1958L29.8989 381.644Z"
              stroke="#2AA98B"
              stroke-width="2"
            />
            <path
              className="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M101.642 42.1421L129.345 1.69824H73.9387L101.642 42.1421Z"
              stroke="#2AA98B"
              stroke-width="2"
            />
            <path
              className="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M101.642 117.853L129.345 77.4092H73.9387L101.642 117.853Z"
              stroke="#2AA98B"
              stroke-width="2"
            />
            <path
              className="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M29.8989 78.7998L57.6021 38.3564H2.1958L29.8989 78.7998Z"
              stroke="#2AA98B"
              stroke-width="2"
            />
            <path
              className="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M29.8989 154.511L57.6021 114.067H2.1958L29.8989 154.511Z"
              stroke="#2AA98B"
              stroke-width="2"
            />
          </svg>
        </span>
        <div className="relative  z-1 mx-0">
          <div className="max-w-[1702px] mx-auto flex w-full flex-col gap-10  self-stretch">
            <h3 class="mainHeadingInner text-purple mt-16 md:mt-5 ">
              SCHEDULE
            </h3>
            <EventSlider />
          </div>

          <div className="max-w-[1702px] mx-auto flex w-full flex-col gap-32 self-stretch">
            <span className="halfCricleLineOne left upper_top ">
              <svg
                width="554"
                height="1107"
                viewBox="0 0 554 1107"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.5"
                  d="M0 1.07324C304.918 1.07324 552.103 248.258 552.103 553.176C552.103 858.094 304.918 1105.28 0 1105.28"
                  stroke="#EB3D71"
                  stroke-width="2"
                  className="path"
                />
              </svg>
            </span>
            <div class="flex flex-col items-center">
              <div class="mr-[92px] ml-2 flex flex-col items-start gap-3 self-stretch lg:mx-0 md:mx-0">
                <h3 class="mainHeadingInner text-orange">SPEAKERS</h3>
                <p class="subheadingInner">
                  Continuous learning and exchange of ideas are essential for
                  growth and innovation. RIEMIX features carefully curated
                  sessions designed to blend diverse perspectives, innovative
                  ideas and practical knowledge that leave you inspired.
                </p>
                <p class="subheadingInner">
                  Expect to engage in a range of interactive sessions,
                  insightful talks, and thought-provoking discussions. Each
                  session is an opportunity to reimagine concepts, explore new
                  approaches, and RIEMIX your understanding in a way that sparks
                  creativity and drives growth.
                </p>
                <p class="subheadingInner">
                  Stay tuned for our specially curated lineup of speakers…
                </p>
              </div>
            </div>
          </div>

          <div
            className={`flex flex-col items-start gap-[54px] sm:gap-[27px] flex-1 py-14`}
          >
            <div className="flex w-[34%] min-w-[240px] flex-col items-center self-center sm-w-100">
              <Img
                src={`${process.env.PUBLIC_URL}/images/img_group_29.png`}
                alt="logo image"
                className="h-[132px] w-full object-contain lg:h-auto md:h-auto"
              />
              <Heading
                size="heading2xl"
                as="h1"
                className="relative mt-[-44px] sm:mt-[-24px] !text-teal-400_01 lg:text-5xl sm:text-3xl progress_heading"
              >
                <span className="text-white-a700 in_progress">IN PROGRESS</span>
                <span className="text-pink-a200 animate-pulse">.</span>
                <span className="text-amber-300_01 animate-pulse delay-300">
                  .
                </span>
                <span className="text-teal-400_01 animate-pulse delay-700">
                  .
                </span>
              </Heading>
            </div>
          </div>

          <div className="max-w-[1702px] mx-auto flex w-full flex-col gap-10 self-stretch">
            <div className="flex flex-col items-start gap-6">
              <h1 className=" mainHeadingInner ">MYEO EXPERIENCES</h1>
              <h2 className="subheadingInner">
                Discover the essence of Bengaluru through specially curated MyEO
                events, designed to show you the hidden and the prominent gems
                of our city.
              </h2>
            </div>

            <div className="mt-[8px] relative flex items-end self-stretch md:flex-col w-full justify-between gap-x-20 event-pic-box">
              <div className="flex flex-1 items-center self-end px-5 md:self-stretch md:px-5 mb-[50px] md:mb-10 sm:px-4 flex-col text-center">
                {/* <Img
                  class="object-cover lg:w-auto lg:h-auto  max-w-full shakeHoverSmall"
                  src={`${process.env.PUBLIC_URL}/images/event/Tipu-Trek-Yelle-Oota-reakfast.png`}
                  alt="Feature Image"
                  loading="lazy"
                />
                <h4 className="subheadingInner sm:mt-[20px]">
                  Tipu Trek + Yelle Oota Breakfast
                </h4> */}

                <div class="flip-card-image" tabIndex="0">
                  <div class="flip-card-inner">
                    <div class="flip-card-front">
                      <Img
                        class="object-cover lg:w-auto lg:h-auto  max-w-full "
                        src={`${process.env.PUBLIC_URL}/images/event/tipu.png`}
                        alt="Feature Image"
                        loading="lazy"
                      />
                    </div>
                    <div
                      class="flip-card-back "
                      style={{ background: "#ECC846", color: "#150539" }}
                    >
                      <p>
                        Elevate your senses with the Tipu Trek at Nandi Hills,
                        where serene beauty and historical allure converge for a
                        remarkable adventure. Embark on a guided trek through
                        verdant trails that once echoed with the footsteps of
                        Tipu Sultan. As dawn breaks, bask in the panoramic
                        vistas of the surrounding landscapes. Conclude this
                        exclusive experience with a scrumptious Yelle Oota
                        breakfast, served on banana leaves, featuring a
                        selection of local delicacies crafted to perfection for
                        the discerning palate.
                      </p>
                    </div>
                  </div>
                </div>
                <h4 className="subheadingInner sm:mt-[20px]">
                  Tipu Trek + Yelle Oota Breakfast
                </h4>
              </div>

              <div className="flex flex-1 items-center self-end px-5 md:self-stretch md:px-5 mb-[50px] md:mb-0 sm:mb-[10px] sm:px-4 flex-col text-center">
                <div class="flip-card-image" tabIndex="0">
                  <div class="flip-card-inner">
                    <div class="flip-card-front">
                      <Img
                        class="object-cover lg:w-auto lg:h-auto  max-w-full shakeHoverSmall"
                        src={`${process.env.PUBLIC_URL}/images/event/nandi.png`}
                        alt="Feature Image"
                        loading="lazy"
                      />
                    </div>
                    <div
                      class="flip-card-back "
                      style={{ background: "#29A98C", color: "white" }}
                    >
                      <p>
                        Indulge in a journey through time with the Nandi
                        Historical Walk, a specially curated tour that reveals
                        the secrets of ancient temples, majestic forts and the
                        stories of yore. Each step takes you deeper into the
                        grandeur of Nandi Hills’ storied past. As the day
                        transitions to evening, surrender to the culinary
                        extravagance of a seven-course dinner by Oota Co, where
                        each dish is a masterpiece celebrating regional
                        flavours. This unique dining experience, set against the
                        backdrop of the hills’ tranquil beauty, promises to be a
                        magical evening of discovery and delight.
                      </p>
                    </div>
                  </div>
                </div>

                <h4 className="subheadingInner sm:mt-[20px]">
                  Nandi Historical Walk + 7 Course Oota Co Dinner
                </h4>
              </div>
            </div>

            <div className="mt-[8px] relative  flex items-end self-stretch md:flex-col w-full justify-between gap-x-20 event-pic-box">
              <div className="flex flex-1 items-center self-end px-5 md:self-stretch md:px-5 mb-[50px]  md:mb-10 sm:px-4 flex-col text-center">
                <div class="flip-card-image" tabIndex="0">
                  <div class="flip-card-inner">
                    <div class="flip-card-front">
                      <Img
                        class="object-cover lg:w-auto lg:h-auto  max-w-full shakeHoverSmall"
                        src={`${process.env.PUBLIC_URL}/images/event/ramen.png`}
                        alt="Feature Image"
                        loading="lazy"
                      />
                    </div>
                    <div
                      class="flip-card-back "
                      style={{ background: "#EB3D71", color: "white" }}
                    >
                      <p>
                        Unveil an evening of sophisticated elegance with a
                        private dinner at the famous (and hard-to-get) Naru
                        Noodle Bar. Curated exclusively for an intimate group of
                        20, this bespoke dining experience features a gourmet
                        menu blending the finest of Japanese and contemporary
                        cuisines, paired perfectly with premium sake. This
                        exclusive dinner promises an unforgettable night of
                        culinary excellence and distinguished company.
                      </p>
                    </div>
                  </div>
                </div>

                <h4 className="subheadingInner sm:mt-[20px]">
                  Dinner + Sake at Naru
                </h4>
              </div>

              <div className="flex flex-1 items-center self-end px-5 md:self-stretch md:px-5 mb-[50px] md:mb-0 sm:mb-[10px]  sm:px-4 flex-col text-center">
                <div class="flip-card-image" tabIndex="0">
                  <div class="flip-card-inner">
                    <div class="flip-card-front">
                      <Img
                        class="object-cover lg:w-auto lg:h-auto  max-w-full "
                        src={`${process.env.PUBLIC_URL}/images/event/pub.png`}
                        alt="Feature Image"
                        loading="lazy"
                      />
                    </div>
                    <div
                      class="flip-card-back "
                      style={{ background: "#9F85FF", color: "#150539" }}
                    >
                      <p>
                        Experience the epitome of urban chic with Tipsy
                        Tippasandra, an elite bar crawl through the vibrant
                        heart of Indiranagar. This one-of-a-kind nightlife
                        experience takes you to Bengaluru’s trendiest bars, pubs
                        and lounges, each offering carefully crafted drinks and
                        an electric ambience as you mingle with fellow
                        connoisseurs. This is more than just a bar crawl; it’s
                        an indulgent journey through the best that the city’s
                        nightlife has to offer.
                      </p>
                    </div>
                  </div>
                </div>

                <h4 className="subheadingInner sm:mt-[20px]">
                  Tipsy Tippasandra - Indiranagar Bar Crawl
                </h4>
              </div>
            </div>

            <div className="mt-[8px] relative  flex items-end self-stretch md:flex-col justify-center w-1/2 md:w-full mx-auto gap-x-20 event-pic-box">
              <div className="flex flex-1 items-center self-end px-5 md:self-stretch md:px-5  sm:px-4 flex-col text-center">
                <div class="flip-card-image" tabIndex="0">
                  <div class="flip-card-inner">
                    <div class="flip-card-front">
                      <Img
                        class="object-cover lg:w-auto lg:h-auto  max-w-full shakeHoverSmall"
                        src={`${process.env.PUBLIC_URL}/images/event/dosa.png`}
                        alt="Feature Image"
                        loading="lazy"
                      />
                    </div>
                    <div
                      class="flip-card-back "
                      style={{ background: "#EB9570", color: "#150539" }}
                    >
                      <p>
                        Embark on a gastronomic odyssey with the Death by Dosa
                        walk in Chikpet, a culinary tour designed for the true
                        epicurean. This curated experience takes you through the
                        bustling heart of Chikpet, where hidden gems and iconic
                        eateries await. Taste a variety of mouth-watering dosas,
                        from the classic to the innovative, each a testament to
                        the rich culinary heritage of Bengaluru. Guided by
                        experts, this tour explores Chikpet’s vibrant food
                        scene, promising a delectable adventure for the most
                        discerning food lovers.
                      </p>
                    </div>
                  </div>
                </div>

                <h4 className="subheadingInner sm:mt-[20px]">
                  Death by Dosa - Chikpet Dosa Trail
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
