import { Heading, Img, Text } from "components";
import React from "react";
import { useState } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";

export default function Footer() {
  const [isOpen, setIsOpen] = useState(false);
  const [modalComingIsOpen, setModalComingIsOpen] = React.useState(false);
  const [activeSection, setActiveSection] = useState("");
  function closeModal() {
    setModalComingIsOpen(false);
  }
  const handleRegsiter = () => {
    setModalComingIsOpen(true);
  };
  const goToSection = (section) => {
    const element = document.getElementById(section);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      setIsOpen(false);
      setActiveSection(section);
    }
  };
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxWidth: "450px",
      width: "calc(100% - 20px)",
      borderRadius: "10px",
    },
  };
  return (
    <>
      <div className="w-full bg-[url(/public/images/f-bottom-circle.png)] f-bottom-circle bg-no-repeat">
        <div className="w-full bg-[url(/public/images/f-arrow.png)] f-arrow bg-no-repeat">
          <div className="w-full bg-[url(/public/images/f-dot-one.png)] f-dot f-dot-one bg-no-repeat">
            <div className="w-full bg-[url(/public/images/f-dot-two.png)] f-dot f-dot-two bg-no-repeat">
              <div className="w-full bg-[url(/public/images/f-dot-three.png)] f-dot f-dot-three bg-no-repeat">
                <div className="w-full bg-[url(/public/images/f-dot-four.png)] f-dot f-dot-four bg-no-repeat">
                  <div className="w-full bg-[url(/public/images/f-dot-five.png)] f-dot f-dot-five bg-no-repeat">
                    <div className="w-full bg-[url(/public/images/f-dot-six.png)] f-dot f-dot-six bg-no-repeat"></div>
                    <div className="container-xs mt-[84px] md:mt-[20px] border-footer lg:p-5 md:p-5">
                      <footer className="footerMain md:mt-[40px] flex items-center justify-between self-stretch md:flex-col ">
                        <div className="w-[52%] lg:w-[42%] md:mb-[40px] sm-w-100 footerlogoMain">
                          <Img
                            src={`${process.env.PUBLIC_URL}/images/footer-logo.png`}
                            alt="featured image"
                            className="footer-logo"
                          />
                        </div>
                        <div className="flex w-[42%] lg:w-[52%] items-start justify-around md:w-full sm:flex-col">
                          <div className="flex flex-col items-start gap-8 self-center md:w-full md:mb-[40px]">
                            <Heading
                              size="headingxs"
                              as="h2"
                              className="!text-[24.21px] !text-amber-300"
                            >
                              QUICK LINKS
                            </Heading>
                            <div className="flex flex-col items-start gap-[30px] sm:gap-[10px]">
                              {/* <a className="cursor-pointer">
                                <Heading
                                  size="headingxs"
                                  as="h3"
                                  className="!text-[24.21px] sm:!text-[16px]"
                                >
                                  HOME
                                </Heading>
                              </a> */}
                              {/* <a
                                onClick={() => goToSection("about")}
                                className={
                                  activeSection === "about" ? "active" : ""
                                }
                              >
                                <Heading
                                  size="headingxs"
                                  as="h4"
                                  className="!text-[24.21px] sm:!text-[16px]"
                                >
                                  ABOUT RIE
                                </Heading>
                              </a> */}
                              {/* <a
                                onClick={() => goToSection("schedule")}
                                className={
                                  activeSection === "schedule" ? "active" : ""
                                }
                              >
                                <Heading
                                  size="headingxs"
                                  as="h5"
                                  className="!text-[24.21px] sm:!text-[16px]"
                                >
                                  EVENT
                                </Heading>
                              </a> */}
                              {/* <a
                                onClick={() => goToSection("planTrip")}
                                className={
                                  activeSection === "planTrip" ? "active" : ""
                                }
                              >
                                <Heading
                                  size="headingxs"
                                  as="h6"
                                  className="!text-[24.21px] sm:!text-[16px]"
                                >
                                  PLAN YOUR TRIP
                                </Heading>
                              </a> */}
                              {/* <a
                                href="https://register.rie2025.com"
                                rel="noreferrer"
                                target="_blank"
                              >
                            
                                <Heading
                                  size="headingxs"
                                  as="h4"
                                  className="!text-[24.21px] sm:!text-[16px]"
                                >
                                  REGISTER
                                </Heading>
                              </a> */}

                              <Link to="/cancellation-policy"                                
                                rel="noreferrer"                                
                              >
                                {/* <a href="#" onClick={()=>{handleRegsiter()}}> */}
                                <Heading
                                  size="headingxs"
                                  as="h4"
                                  className="!text-[24.21px] sm:!text-[16px]"
                                >
                                  CANCELLATION POLICY
                                </Heading>
                              </Link>
                              <Link to="/faq" className="cursor-pointer">
                                <Heading
                                  size="headingxs"
                                  as="h3"
                                  className="!text-[24.21px] sm:!text-[16px]"
                                >
                                  FAQ
                                </Heading>
                              </Link>
                            </div>
                            <Heading
                              size="headingxs"
                              as="h2"
                              className="!text-[24.21px] !text-amber-300"
                            >
                              CONTACT US
                            </Heading>
                            <div className="flex flex-col items-start gap-[30px] sm:gap-[10px]">
                              <a
                                href="mailto:rie@eobangalore.com"
                                rel="noreferrer"
                                target="_blank"
                              >
                                <Heading
                                  size="headingxs"
                                  as="h4"
                                  className="!text-[24.21px] sm:!text-[16px]"
                                >
                                  rie@eobangalore.com
                                </Heading>
                              </a>

                              <a
                                href="tel:+91 9620011999"
                                rel="noreferrer"
                                target="_blank"
                              >
                                <Heading
                                  size="headingxs"
                                  as="h4"
                                  className="!text-[24.21px] sm:!text-[16px]"
                                >
                                  +91 9620011999
                                </Heading>
                              </a>
                            </div>
                          </div>
                          <div className="flex w-[25%] flex-col items-start gap-8 sm:gap-[10px] md:w-full">
                            <Heading
                              size="headingxs"
                              as="h2"
                              className="!text-[24.21px] !text-amber-300"
                            >
                              SOCIALS
                            </Heading>
                            <div className="flex flex-col items-start self-stretch">
                              {/* <a href="LANGUAGE" target="_blank" rel="noreferrer">
                <Text as="p" className="!text-[24.21px] sm:!text-[16px]">
                  LANGUAGE
                </Text>
              </a> */}
                              {/* <a
                href="DOWNLOADS"
                target="_blank"
                rel="noreferrer"
                className="mt-[30px] sm:mt-[10px]"
              >
                <Text as="p" className="!text-[24.21px] sm:!text-[16px]">
                  DOWNLOADS
                </Text>
              </a>
              <Text
                as="p"
                className="mt-[30px] !text-[24.21px] sm:!text-[16px] !text-amber-300 sm:mt-[10px]"
              >
                SOCIALS
              </Text> */}
                              <div className="mt-5 flex items-center justify-between md:justify-start gap-5 self-stretch">
                                <a
                                  href="https://www.instagram.com/riemix2025/"
                                  className=" mr-2"
                                  target="_blank"
                                >
                                  {" "}
                                  <Img
                                    src={`${process.env.PUBLIC_URL}/images/img_info.svg`}
                                    alt="info icon"
                                    className="h-[32px] w-[32px]"
                                  />
                                </a>
                                <a
                                  href="https://www.facebook.com/profile.php?id=61561977496128"
                                  className=" mr-2"
                                  target="_blank"
                                >
                                  {""}
                                  <Img
                                    src={`${process.env.PUBLIC_URL}/images/img_facebook.svg`}
                                    alt="facebook icon"
                                    className="h-[30px]"
                                  />
                                </a>

                                <a href="" className=" mr-2  opacity-0">
                                  <Img
                                    src={`${process.env.PUBLIC_URL}/images/img_close.svg`}
                                    alt="close icon"
                                    className="h-[26px] w-[26px]"
                                  />
                                </a>
                              </div>
                              <a
                                href="https://wa.me/919035734249"
                                target="_blank"
                                className=" mr-2 whatsapp-icon"
                              >
                                {" "}
                                <Img
                                  src={`${process.env.PUBLIC_URL}/images/WhatsApp_icon.png`}
                                  alt="volume icon"
                                  className="h-[50px] w-[50px]"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </footer>
                      <div className="w-full justify-center flex-col flex">
                        <ul className="footerBottomMenu">
                          <li>
                            <a
                              href={`https://register.rie2025.com/termsandconditions`}
                              rel="noreferrer"
                              target="_blank"
                            >
                              TERMS OF USE
                            </a>
                          </li>
                          <li>
                            <a
                              href={`https://register.rie2025.com/privacypolicy`}
                              rel="noreferrer"
                              target="_blank"
                            >
                              PRIVACY POLICY
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalComingIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button onClick={closeModal} className="btnClose">
          <Img
            src={`${process.env.PUBLIC_URL}/images/close.svg`}
            alt="image"
            width="16px"
            height="16px"
          />
        </button>
        <div className="py-20 px-10">
          <Img
            src={`${process.env.PUBLIC_URL}/images/popup-logo.png`}
            alt="image"
            className="popupLogo"
          />
          <p className="text-[24px] text-center w-full mt-5">
            Opening at 18:30
          </p>
        </div>
      </Modal>
    </>
  );
}
