import Footer from "components/Footer/Footer";
import Header from "components/Header";
import React, { Suspense, useState, useRef, useEffect, Fragment } from "react";
import { Img, Text, Heading, Button } from "../../components";

import { Helmet } from "react-helmet";

export default function AboutUs() {
  const videoRef1 = useRef(null); // Reference to the video element
  const videoRef2 = useRef(null); // Reference to the video element
  const videoRef3 = useRef(null); // Reference to the video element
  const [isPlaying, setIsPlaying] = useState({
    video1: true,
    video2: true,
    video3: true,
  }); // State to manage play/pause
  const isiPhone = /iPhone/.test(navigator.userAgent);
  const [isHovered, setIsHovered] = useState(false);
  const [imgFileName, setImgFileName] = useState([]);

  const handleRegsiter = () => {
    let url = "https://register.rie2025.com";
    window.open(url, "_blank", "noreferrer");
    // setModalComingIsOpen(true);
  };
  const handlePlayPause = (videoKey, videoRef) => {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
      setIsPlaying({ ...isPlaying, [videoKey]: true });
    } else {
      video.pause();
      setIsPlaying({ ...isPlaying, [videoKey]: false });
    }
  };
  const toggleHover = (ind, hover = false) => {
    setImgFileName((prev) =>
      prev.map((c, i) =>
        i === ind ? (hover ? `essance-${i + 1}-hover` : `essance-${i + 1}`) : c
      )
    );
  };

  useEffect(() => {
    let arr = [];
    for (let i = 0; i < 10; i++) {
      arr.push(`essance-${i + 1}`);
    }
    setImgFileName(arr);
  }, []);

  return (
    <>
      <Helmet>
        <title>About RIE - Celebrating EO Bangalore&#39;s 25-Year Legacy</title>
        <meta
          name="description"
          content="Discover the essence of RIE, a curated 3-day event by EO Bangalore that blends world-class learning and networking with a rich cultural showcase. RIEMIX 2025 marks a remix of tradition and innovation."
        />
      </Helmet>

      <div className="flex justify-center self-stretch bg-black-900_7f py-[60px] lg:py-8 md:py-5 sm:py-4 video-container vdo-home ratio ratio-16x9">
        <div className="max-w-[1702px] mx-auto flex w-full flex-col gap-32 self-stretch lg:gap-24 lg:p-12 md:gap-24 md:p-5 sm:gap-16 py-144">
          <div className="flex w-[50%] flex-col items-center justify-end  lg:w-full md:w-full">
            <div className="">
              <video
                width="100%"
                className="video-background"
                ref={videoRef1}
                playsInline
                autoPlay
                loop
                muted
              >
                <source
                  src={`${process.env.PUBLIC_URL}/video/about_page.mp4`}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>

              <div className="videoDescp content">
                {isiPhone && (
                  <button
                    id="myBtn"
                    className="mt-[48px] lg:mt-[148px] md:mt-[78px]"
                    onClick={() => handlePlayPause("video1", videoRef1)}
                  >
                    {/* {isPlaying ? "Pause" : "Play"} */}
                    {/* <Img
                        src="images/img_frame_icon.svg"
                        alt="event image"
                        className="h-[234px] w-[100%] md:h-[100px]"
                      /> */}
                    <Img
                      src={`${process.env.PUBLIC_URL}/images/play.png`}
                      alt="play"
                      className="h-[50px] w-auto md:h-[50px]"
                    />
                    {/* <img  src={`${process.env.PUBLIC_URL}/images/play.png`} /> */}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="aboutBg">
        <span className="halfCricleLineTwo  ">
          <svg
            width="518"
            height="1035"
            viewBox="0 0 518 1035"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              class="line"
              d="M518 1034.43C232.6 1034.43 1.23694 803.071 1.23694 517.671C1.23694 232.271 232.6 0.908203 518 0.908203"
              stroke="#ECC846"
            />
            <path
              class="line"
              d="M518 946.147C281.359 946.147 89.5239 754.312 89.5239 517.671C89.5239 281.03 281.359 89.1953 518 89.1953"
              stroke="#ECC846"
            />
          </svg>
        </span>

        <span className="arrowDownLeftRed">
          <svg
            width="151"
            height="857"
            viewBox="0 0 151 357"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              class="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M34.4683 48.3972L66.3947 1.78796H2.54199L34.4683 48.3972Z"
              stroke="#EB3D71"
              stroke-width="2"
            />
            <path
              class="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M34.4683 135.083L66.3947 88.4739H2.54199L34.4683 135.083Z"
              stroke="#EB3D71"
              stroke-width="2"
            />
            <path
              class="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M34.4683 221.769L66.3947 175.16H2.54199L34.4683 221.769Z"
              stroke="#EB3D71"
              stroke-width="2"
            />
            <path
              class="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M116.187 95.0061L148.114 48.397H84.261L116.187 95.0061Z"
              stroke="#EB3D71"
              stroke-width="2"
            />
            <path
              class="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M116.187 181.692L148.114 135.083H84.261L116.187 181.692Z"
              stroke="#EB3D71"
              stroke-width="2"
            />
            <path
              class="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M116.187 268.378L148.114 221.769H84.261L116.187 268.378Z"
              stroke="#EB3D71"
              stroke-width="2"
            />
            <path
              class="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M34.4683 308.455L66.3947 261.846H2.54199L34.4683 308.455Z"
              stroke="#EB3D71"
              stroke-width="2"
            />
            <path
              class="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M116.187 355.064L148.114 308.455H84.261L116.187 355.064Z"
              stroke="#EB3D71"
              stroke-width="2"
            />
          </svg>
        </span>

        <span className="arrowDownLeftRed bottom">
          <svg
            width="131"
            height="883"
            viewBox="0 0 131 383"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="path2"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M29.6621 189.853L1.95898 230.297H57.3653L29.6621 189.853Z"
              stroke="#ED3B70"
              stroke-width="2"
            />
            <path
              className="path2"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M29.6621 114.142L1.95898 154.586H57.3653L29.6621 114.142Z"
              stroke="#ED3B70"
              stroke-width="2"
            />
            <path
              className="path2"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M29.6621 38.4312L1.95898 78.875H57.3653L29.6621 38.4312Z"
              stroke="#ED3B70"
              stroke-width="2"
            />
            <path
              className="path2"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M101.406 153.195L73.7029 193.639H129.109L101.406 153.195Z"
              stroke="#ED3B70"
              stroke-width="2"
            />
            <path
              className="path2"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M101.406 77.4839L73.7029 117.928H129.109L101.406 77.4839Z"
              stroke="#ED3B70"
              stroke-width="2"
            />
            <path
              className="path2"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M101.406 1.77295L73.7029 42.2168H129.109L101.406 1.77295Z"
              stroke="#ED3B70"
              stroke-width="2"
            />
            <path
              className="path2"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M29.6621 341.275L1.95898 381.718L57.3653 381.719L29.6621 341.275Z"
              stroke="#ED3B70"
              stroke-width="2"
            />
            <path
              className="path2"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M29.6621 265.564L1.95898 306.008H57.3653L29.6621 265.564Z"
              stroke="#ED3B70"
              stroke-width="2"
            />
            <path
              className="path2"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M101.405 304.617L73.7019 345.061H129.108L101.405 304.617Z"
              stroke="#ED3B70"
              stroke-width="2"
            />
            <path
              className="path2"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M101.405 228.906L73.7019 269.349H129.108L101.405 228.906Z"
              stroke="#ED3B70"
              stroke-width="2"
            />
          </svg>
        </span>

        <span className="arrowDownLeftRed right">
          <svg
            width="150"
            height="358"
            viewBox="0 0 150 358"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M33.9593 48.5962L65.8856 1.98706H2.03296L33.9593 48.5962Z"
              stroke="#ECC846"
              stroke-width="2"
            />
            <path
              className="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M33.9593 132.016L65.8856 85.407H2.03296L33.9593 132.016Z"
              stroke="#ECC846"
              stroke-width="2"
            />
            <path
              className="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M33.9593 215.436L65.8856 168.827H2.03296L33.9593 215.436Z"
              stroke="#ECC846"
              stroke-width="2"
            />
            <path
              className="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M33.9593 308.654L65.8856 262.045H2.03296L33.9593 308.654Z"
              stroke="#ECC846"
              stroke-width="2"
            />
            <path
              className="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M115.678 95.2051L147.605 48.5959H83.752L115.678 95.2051Z"
              stroke="#ECC846"
              stroke-width="2"
            />
            <path
              className="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M115.678 178.625L147.605 132.016H83.752L115.678 178.625Z"
              stroke="#ECC846"
              stroke-width="2"
            />
            <path
              className="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M115.678 262.045L147.605 215.436H83.752L115.678 262.045Z"
              stroke="#ECC846"
              stroke-width="2"
            />
            <path
              className="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M115.678 355.264L147.605 308.655H83.752L115.678 355.264Z"
              stroke="#ECC846"
              stroke-width="2"
            />
          </svg>
        </span>

        <span className="arrowDownLeftRed right two">
          <svg
            width="132"
            height="384"
            viewBox="0 0 132 384"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M101.642 193.564L129.345 153.12H73.9387L101.642 193.564Z"
              stroke="#2AA98B"
              stroke-width="2"
            />
            <path
              className="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M101.642 269.275L129.345 228.831H73.9387L101.642 269.275Z"
              stroke="#2AA98B"
              stroke-width="2"
            />
            <path
              className="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M101.642 344.985L129.345 304.542H73.9387L101.642 344.985Z"
              stroke="#2AA98B"
              stroke-width="2"
            />
            <path
              className="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M29.8989 230.222L57.6021 189.778H2.1958L29.8989 230.222Z"
              stroke="#2AA98B"
              stroke-width="2"
            />
            <path
              className="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M29.8989 305.933L57.6021 265.489H2.1958L29.8989 305.933Z"
              stroke="#2AA98B"
              stroke-width="2"
            />
            <path
              className="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M29.8989 381.644L57.6021 341.2H2.1958L29.8989 381.644Z"
              stroke="#2AA98B"
              stroke-width="2"
            />
            <path
              className="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M101.642 42.1421L129.345 1.69824H73.9387L101.642 42.1421Z"
              stroke="#2AA98B"
              stroke-width="2"
            />
            <path
              className="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M101.642 117.853L129.345 77.4092H73.9387L101.642 117.853Z"
              stroke="#2AA98B"
              stroke-width="2"
            />
            <path
              className="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M29.8989 78.7998L57.6021 38.3564H2.1958L29.8989 78.7998Z"
              stroke="#2AA98B"
              stroke-width="2"
            />
            <path
              className="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M29.8989 154.511L57.6021 114.067H2.1958L29.8989 154.511Z"
              stroke="#2AA98B"
              stroke-width="2"
            />
          </svg>
        </span>
        <div className="relative z-1">
          <div className="max-w-[1702px] mx-auto flex w-full flex-col gap-32 self-stretch lg:gap-24 lg:p-12 md:gap-24 md:p-5 sm:gap-16 py-14">
            <div className="flex flex-col items-start gap-6">
              <h1 className=" mainHeadingInner ">WHAT IS RIE?</h1>
              <h2 className="subheadingInner">
                RIE (Regional Integration Event) is a meticulously curated three
                day event designed to offer EO members world-class learning,
                networking opportunities and exclusive experiences. It brings
                together the EO community from South Asia and beyond in an
                exceptional showcase of ideas, innovation and culture.{" "}
              </h2>
            </div>
          </div>
          <div className="max-w-[1702px] mx-auto flex w-full flex-col gap-32 self-stretch lg:gap-24 lg:p-12 md:gap-24 md:p-5 sm:gap-16 py-14">
            <div class="flex flex-col items-center">
              <div class="mr-[92px] ml-2 flex flex-col items-start gap-3 self-stretch lg:mx-0 md:mx-0">
                <h3 class="mainHeadingInner text-orange">
                  BACK TO WHERE IT BEGAN
                </h3>
                <h4 class="subheadingInner">
                  EO Bangalore has a long history with RIE, having initiated the
                  concept in 2004. This is the third time <br />
                  we are hosting RIE, and are excited to bring new energy into
                  this now two decades-old tradition.
                </h4>
              </div>
              <div class="mt-7 self-stretch ">
                <Img
                  class=" w-full shakeHoverLarge "
                  src={`${process.env.PUBLIC_URL}/images/img_rectangle_18_670x1702.png`}
                  alt="Banner Image"
                  loading="lazy"
                />
              </div>
              <h5 class="subheadingInner mt-5">
                RIEMIX 2025 is just that - a true remix of fresh ideas and
                established traditions. Where we take <br />
                something familiar and add a new flavour to it, making it better
                than ever before.
              </h5>
              <div class="mx-2 mt-20 flex flex-col gap-9 self-stretch lg:mx-0 md:mx-0 essence-main ">
                <div class="essence-gap flex flex-wrap items-end">
                  <span class="EssenceHeadingsmall text-white">
                    EO BANGALORE PRESENTS…
                  </span>
                  <span class="EssenceHeadingBig text-purple">RIEMIX</span>
                </div>
                <h2 class="subheadingInner">
                  Celebrating 25 years of EO Bangalore's rich legacy, RIEMIX
                  brings together the best of the past <br />
                  and the promise of the future. The theme represents a
                  harmonious blend of experiences and <br />
                  aspirations, creating a vibrant platform for immersive
                  learning, collaboration and inspiration.
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div className="max-w-[1702px] relative z-1 mx-auto flex w-full flex-col gap-32 self-stretch lg:gap-24 lg:p-12 md:gap-24 md:p-5 sm:gap-16 py-14">
          <span className="halfCricleLineOne left  ">
            <svg
              width="554"
              height="1107"
              viewBox="0 0 554 1107"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.5"
                d="M0 1.07324C304.918 1.07324 552.103 248.258 552.103 553.176C552.103 858.094 304.918 1105.28 0 1105.28"
                stroke="#EB3D71"
                stroke-width="2"
                className="path"
              />
            </svg>
          </span>

          <div class="sm:gap-[19px] md:gap-[78px] lg:gap-[104px] gap-[104px] mr-[30px] flex flex-col lg:mr-0 md:mr-0">
            <div class="mr-[60px] ml-[102px] flex items-end lg:mx-0 md:mx-0 md:flex-col">
              <div class="mb-10 flex flex-1 md:self-stretch sm:justify-center circalImg">
                <div class="rounded-[18px] w-[36px] h-[36px] bg-amber-300"></div>
                <div class="rounded-[18px] w-[36px] h-[36px] bg-pink-400_01"></div>
                <div class="rounded-[18px] w-[36px] h-[36px] ml-1 bg-indigo-600"></div>
                <div class="rounded-[18px] w-[36px] h-[36px] ml-3.5 bg-teal-400"></div>
                <div class="rounded-[18px] ml-[26px] w-[36px] h-[36px] bg-amber-300"></div>
                <div class="rounded-[18px] ml-[30px] w-[36px] h-[36px] bg-teal-400"></div>
              </div>
              <img
                class="w-[36%] md:w-[80%] h-[122px] self-center object-contain md:w-full sm:h-auto"
                src={`${process.env.PUBLIC_URL}/images/remix-logo.svg`}
                alt="Main Image"
                loading="lazy"
              />

              <div class="mb-10 flex flex-1 justify-end md:self-stretch sm:justify-center sm:mt-8 circalImg1">
                <div class="rounded-[18px] w-[36px] h-[36px] bg-deep_orange-300"></div>
                <div class="rounded-[18px] ml-[30px] w-[36px] h-[36px] bg-amber-300"></div>
                <div class="rounded-[18px] ml-[26px] w-[36px] h-[36px] bg-teal-400"></div>
                <div class="rounded-[18px] w-[36px] h-[36px] ml-3.5 bg-indigo-600"></div>
                <div class="rounded-[18px] w-[36px] h-[36px] ml-1 bg-pink-400_01"></div>
                <div class="rounded-[18px] w-[36px] h-[36px] bg-amber-300"></div>
              </div>
            </div>
            <div class="sm:gap-[19px] md:gap-[88px] lg:gap-[118px] gap-[118px] flex flex-col riemixMain">
              <div>
                <div class="flex flex-col items-center">
                  <h2 class="subheadingInner">
                    RIEMIX, much like the improvisations of jazz musicians in
                    the early 20th century to the groundbreaking techniques of
                    hip-hop DJs in the 1970s, is about taking something familiar
                    and transforming it into something new and exciting. But
                    RIEMIX isn't limited to just music or art. We believe it is
                    a universal language that permeates every aspect of our
                    lives.
                  </h2>

                  <div class="relative flex shakeMain">
                    <div class="">
                      <Img
                        class="shakeHover"
                        src={`${process.env.PUBLIC_URL}/images/img_rectangle_20.png`}
                        alt="Feature Image"
                        loading="lazy"
                      />
                    </div>
                    <div class="">
                      <Img
                        class="shakeHover"
                        src={`${process.env.PUBLIC_URL}/images/img_rectangle_19_612x606.png`}
                        alt="Secondary Image"
                        loading="lazy"
                      />
                    </div>
                    <div class="">
                      <Img
                        class="shakeHover"
                        src={`${process.env.PUBLIC_URL}/images/img_rectangle_21.png`}
                        alt="Tertiary Image"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <h2 class="subheadingInner">
                RIEMIX is for the new and the old, the young and the
                experienced, the work and the play - a true symphony of
                emotions, cultures and ideas.
              </h2>
            </div>
          </div>
        </div>

        <div className="max-w-[1702px] mx-auto flex w-full flex-col  self-stretch lg:gap-24 lg:p-12 md:gap-24 md:p-5 sm:gap-16 py-14">
          <div class="essence-gap flex flex-wrap items-end mb-[120px] sm:mb-[0px]">
            <span class="EssenceHeadingsmall text-white">ESSENCE OF</span>
            <span class="EssenceHeadingBig text-green">RIEMIX</span>
          </div>
          <div class="grid grid-cols-3 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4 essenceSec">
            {imgFileName.map((filename, ind) =>
              ind < 9 ? (
                <Fragment key={ind}>
                  <div class="p-4 justify-center mb-14 ">
                    <div className="text-center essenceCol">
                      <Img
                        class=" object-cover lg:w-auto md:w-auto md:w-full max-w-full mx-auto"
                        src={`${process.env.PUBLIC_URL}/images/${filename}.svg`}
                        onMouseEnter={() => toggleHover(ind, true)}
                        onMouseLeave={() => toggleHover(ind, false)}
                        alt="Tertiary Image"
                        loading="lazy"
                      />
                      {/* <div className="hoverEfeect">Emotions</div> */}
                    </div>
                  </div>
                </Fragment>
              ) : null
            )}
          </div>
          <div class="p-4 justify-center  rounded sm">
            <div className="text-center essenceCol">
              <Img
                class=" object-cover lg:w-auto md:w-full max-w-full mx-auto"
                src={`${process.env.PUBLIC_URL}/images/${imgFileName[9]}.svg`}
                onMouseEnter={() => toggleHover(9, true)}
                onMouseLeave={() => toggleHover(9, false)}
                alt="Tertiary Image"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
