import Footer from "components/Footer/Footer";
import React, { useState, useRef, useEffect } from "react";
import { Img } from "../../components";

import { Helmet } from "react-helmet";
import HoverImage from "components/hoverImage/hoverImg";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

export default function PlanTrip() {
  const videoRef1 = useRef(null); // Reference to the video element
  const location = useLocation();
  const scrollInfo = location.state;

  const [isPlaying, setIsPlaying] = useState({
    video1: true,
    video2: true,
    video3: true,
  }); // State to manage play/pause
  const isiPhone = /iPhone/.test(navigator.userAgent);
  const [isHovered, setIsHovered] = useState(false);

  const handleRegsiter = () => {
    let url = "https://register.rie2025.com";
    window.open(url, "_blank", "noreferrer");
    // setModalComingIsOpen(true);
  };
  const handlePlayPause = (videoKey, videoRef) => {
    const video = videoRef.current;
    if (video) {
      if (video.paused) {
        video.play();
        setIsPlaying({ ...isPlaying, [videoKey]: true });
      } else {
        video.pause();
        setIsPlaying({ ...isPlaying, [videoKey]: false });
      }
    }
  };
  useEffect(() => {
    if (scrollInfo?.divid) {
      const timer = setTimeout(() => {
        const id = scrollInfo.divid;
        const element = document.getElementById(id);

        if (element) {
          const rect = element.getBoundingClientRect();
          const scrollTop =
            window.pageYOffset || document.documentElement.scrollTop;
          const targetPosition = rect.top + scrollTop - 70;

          window.scrollTo({
            top: targetPosition,
            behavior: "smooth",
          });
        }
      }, 100); // Increased delay

      return () => clearTimeout(timer);
    }
  }, [scrollInfo]);

  return (
    <>
      <Helmet>
        <title>
          Plan Your Trip - Celebrating EO Bangalore&#39;s 25-Year Legacy
        </title>
        <meta
          name="description"
          content="Discover the essence of RIE, a curated 3-day event by EO Bangalore that blends world-class learning and networking with a rich cultural showcase. RIEMIX 2025 marks a remix of tradition and innovation."
        />
      </Helmet>
      <div className="flex justify-center self-stretch bg-black-900_7f py-[60px] lg:py-8 md:py-5 sm:py-4 video-container vdo-home ratio ratio-16x9">
        <div className="max-w-[1702px] mx-auto flex w-full flex-col gap-32 self-stretch lg:gap-24 lg:p-12 md:gap-24 md:p-5 sm:gap-16 py-144">
          <div className="flex w-[50%] flex-col items-center justify-end  lg:w-full md:w-full">
            <div className="">
              <video
                width="100%"
                className="video-background"
                ref={videoRef1}
                playsInline
                autoPlay
                loop
                muted
              >
                <source
                  src={`${process.env.PUBLIC_URL}/video/plan-you-trip.mp4`}
                  type="video/mp4"
                />
                <source
                  src={`${process.env.PUBLIC_URL}/video/plan-you-trip.webm`}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>

              <div className="videoDescp content">
                {isiPhone && (
                  <button
                    id="myBtn"
                    className="mt-[48px] lg:mt-[148px] md:mt-[78px]"
                    onClick={() => handlePlayPause("video1", videoRef1)}
                  >
                    {/* {isPlaying ? "Pause" : "Play"} */}
                    {/* <Img
                        src="images/img_frame_icon.svg"
                        alt="event image"
                        className="h-[234px] w-[100%] md:h-[100px]"
                      /> */}
                    <Img
                      src={`${process.env.PUBLIC_URL}/images/play.png`}
                      alt="play"
                      className="h-[50px] w-auto md:h-[50px]"
                    />
                    {/* <img  src={`${process.env.PUBLIC_URL}/images/play.png`} /> */}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="aboutBg">
        <div className="max-w-[1702px] customContainer mx-auto flex w-full flex-col gap-32 self-stretch lg:gap-24 lg:p-12 md:gap-24 md:p-5 sm:gap-16 py-14">
          <span className="halfCricleLineTwo  ">
            <svg
              width="518"
              height="1035"
              viewBox="0 0 518 1035"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                class="line"
                d="M518 1034.43C232.6 1034.43 1.23694 803.071 1.23694 517.671C1.23694 232.271 232.6 0.908203 518 0.908203"
                stroke="#ECC846"
              />
              <path
                class="line"
                d="M518 946.147C281.359 946.147 89.5239 754.312 89.5239 517.671C89.5239 281.03 281.359 89.1953 518 89.1953"
                stroke="#ECC846"
              />
            </svg>
          </span>

          <span className="arrowDownLeftRed">
            <svg
              width="151"
              height="857"
              viewBox="0 0 151 357"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                class="path"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M34.4683 48.3972L66.3947 1.78796H2.54199L34.4683 48.3972Z"
                stroke="#29A98C"
                stroke-width="2"
              />
              <path
                class="path"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M34.4683 135.083L66.3947 88.4739H2.54199L34.4683 135.083Z"
                stroke="#29A98C"
                stroke-width="2"
              />
              <path
                class="path"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M34.4683 221.769L66.3947 175.16H2.54199L34.4683 221.769Z"
                stroke="#29A98C"
                stroke-width="2"
              />
              <path
                class="path"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M116.187 95.0061L148.114 48.397H84.261L116.187 95.0061Z"
                stroke="#29A98C"
                stroke-width="2"
              />
              <path
                class="path"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M116.187 181.692L148.114 135.083H84.261L116.187 181.692Z"
                stroke="#29A98C"
                stroke-width="2"
              />
              <path
                class="path"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M116.187 268.378L148.114 221.769H84.261L116.187 268.378Z"
                stroke="#29A98C"
                stroke-width="2"
              />
              <path
                class="path"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M34.4683 308.455L66.3947 261.846H2.54199L34.4683 308.455Z"
                stroke="#29A98C"
                stroke-width="2"
              />
              <path
                class="path"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M116.187 355.064L148.114 308.455H84.261L116.187 355.064Z"
                stroke="#29A98C"
                stroke-width="2"
              />
            </svg>
          </span>

          <span className="arrowDownLeftRed bottom">
            <svg
              width="131"
              height="883"
              viewBox="0 0 131 383"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="path2"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M29.6621 189.853L1.95898 230.297H57.3653L29.6621 189.853Z"
                stroke="#ED3B70"
                stroke-width="2"
              />
              <path
                className="path2"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M29.6621 114.142L1.95898 154.586H57.3653L29.6621 114.142Z"
                stroke="#ED3B70"
                stroke-width="2"
              />
              <path
                className="path2"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M29.6621 38.4312L1.95898 78.875H57.3653L29.6621 38.4312Z"
                stroke="#ED3B70"
                stroke-width="2"
              />
              <path
                className="path2"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M101.406 153.195L73.7029 193.639H129.109L101.406 153.195Z"
                stroke="#ED3B70"
                stroke-width="2"
              />
              <path
                className="path2"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M101.406 77.4839L73.7029 117.928H129.109L101.406 77.4839Z"
                stroke="#ED3B70"
                stroke-width="2"
              />
              <path
                className="path2"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M101.406 1.77295L73.7029 42.2168H129.109L101.406 1.77295Z"
                stroke="#ED3B70"
                stroke-width="2"
              />
              <path
                className="path2"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M29.6621 341.275L1.95898 381.718L57.3653 381.719L29.6621 341.275Z"
                stroke="#ED3B70"
                stroke-width="2"
              />
              <path
                className="path2"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M29.6621 265.564L1.95898 306.008H57.3653L29.6621 265.564Z"
                stroke="#ED3B70"
                stroke-width="2"
              />
              <path
                className="path2"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M101.405 304.617L73.7019 345.061H129.108L101.405 304.617Z"
                stroke="#ED3B70"
                stroke-width="2"
              />
              <path
                className="path2"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M101.405 228.906L73.7019 269.349H129.108L101.405 228.906Z"
                stroke="#ED3B70"
                stroke-width="2"
              />
            </svg>
          </span>

          <span className="arrowDownLeftRed right">
            <svg
              width="150"
              height="358"
              viewBox="0 0 150 358"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="path"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M33.9593 48.5962L65.8856 1.98706H2.03296L33.9593 48.5962Z"
                stroke="#ECC846"
                stroke-width="2"
              />
              <path
                className="path"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M33.9593 132.016L65.8856 85.407H2.03296L33.9593 132.016Z"
                stroke="#ECC846"
                stroke-width="2"
              />
              <path
                className="path"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M33.9593 215.436L65.8856 168.827H2.03296L33.9593 215.436Z"
                stroke="#ECC846"
                stroke-width="2"
              />
              <path
                className="path"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M33.9593 308.654L65.8856 262.045H2.03296L33.9593 308.654Z"
                stroke="#ECC846"
                stroke-width="2"
              />
              <path
                className="path"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M115.678 95.2051L147.605 48.5959H83.752L115.678 95.2051Z"
                stroke="#ECC846"
                stroke-width="2"
              />
              <path
                className="path"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M115.678 178.625L147.605 132.016H83.752L115.678 178.625Z"
                stroke="#ECC846"
                stroke-width="2"
              />
              <path
                className="path"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M115.678 262.045L147.605 215.436H83.752L115.678 262.045Z"
                stroke="#ECC846"
                stroke-width="2"
              />
              <path
                className="path"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M115.678 355.264L147.605 308.655H83.752L115.678 355.264Z"
                stroke="#ECC846"
                stroke-width="2"
              />
            </svg>
          </span>

          <span className="arrowDownLeftRed right two">
            <svg
              width="132"
              height="384"
              viewBox="0 0 132 384"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="path"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M101.642 193.564L129.345 153.12H73.9387L101.642 193.564Z"
                stroke="#2AA98B"
                stroke-width="2"
              />
              <path
                className="path"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M101.642 269.275L129.345 228.831H73.9387L101.642 269.275Z"
                stroke="#2AA98B"
                stroke-width="2"
              />
              <path
                className="path"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M101.642 344.985L129.345 304.542H73.9387L101.642 344.985Z"
                stroke="#2AA98B"
                stroke-width="2"
              />
              <path
                className="path"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M29.8989 230.222L57.6021 189.778H2.1958L29.8989 230.222Z"
                stroke="#2AA98B"
                stroke-width="2"
              />
              <path
                className="path"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M29.8989 305.933L57.6021 265.489H2.1958L29.8989 305.933Z"
                stroke="#2AA98B"
                stroke-width="2"
              />
              <path
                className="path"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M29.8989 381.644L57.6021 341.2H2.1958L29.8989 381.644Z"
                stroke="#2AA98B"
                stroke-width="2"
              />
              <path
                className="path"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M101.642 42.1421L129.345 1.69824H73.9387L101.642 42.1421Z"
                stroke="#2AA98B"
                stroke-width="2"
              />
              <path
                className="path"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M101.642 117.853L129.345 77.4092H73.9387L101.642 117.853Z"
                stroke="#2AA98B"
                stroke-width="2"
              />
              <path
                className="path"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M29.8989 78.7998L57.6021 38.3564H2.1958L29.8989 78.7998Z"
                stroke="#2AA98B"
                stroke-width="2"
              />
              <path
                className="path"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M29.8989 154.511L57.6021 114.067H2.1958L29.8989 154.511Z"
                stroke="#2AA98B"
                stroke-width="2"
              />
            </svg>
          </span>

          <div className="flex flex-col items-start gap-6">
            <h1 className="mainHeadingInner">PLAN YOUR TRIP</h1>
            <h2 className="subheadingInner">
              Bangalore a.k.a. Bengaluru, often referred to as the ‘Silicon
              Valley of India’, is a vibrant city where tradition blends
              seamlessly with modernity. Known for its lush gardens and
              temperate climate, Bengaluru is a haven for both nature lovers and
              tech enthusiasts. The city boasts a dynamic entrepreneurial
              spirit, with countless startups and tech giants calling it home.
              Its diverse culture is reflected in the variety of cuisines, arts,
              and festivals celebrated throughout the year. <br /> <br />
              With top-tier educational institutions, a burgeoning arts scene,
              and a welcoming community, Bengaluru is a city that inspires
              innovation, fosters growth, and celebrates the rich tapestry of
              its heritage.
            </h2>
            <HoverImage
              defaultImage={`${process.env.PUBLIC_URL}/images/img_asset_1_1.png`}
              hoverImage={`${process.env.PUBLIC_URL}/images/img_asset_1_1_hover.png`}
              altText="Hover image example"
              className=" max-w-full  object-cover lg:h-auto md:h-auto  px-8 md:px-3 m-auto relative z-1"
            />
          </div>
        </div>
        <div className="max-w-[1702px] mx-auto flex w-full flex-col gap-24 self-stretch lg:gap-10 lg:p-2 md:gap-10 md:p-5 sm:gap-10 py-14">
          <div className="mx-2  flex flex-col gap-4 self-stretch lg:mx-0 md:mx-0">
            <div className="gap-[31px] flex flex-wrap items-center">
              <h2 className="mainHeadingInner text-red">VENUE</h2>
            </div>
            <h2 className=" subheadingInner">
              The venue for RIEMIX 2025 is the sprawling{" "}
              <a
                style={{ display: "inline", color: "#424ea0" }}
                className="cursor-pointer"
                target="_blank"
                href="https://www.marriott.com/en-us/hotels/blrnh-jw-marriott-bengaluru-prestige-golfshire-resort-and-spa/overview/?gclid=Cj0KCQjwsuSzBhCLARIsAIcdLm4TMvFAaITa-utXTPz9QuLBZgi-Ln0ASigYwia1vySNKJuOM_nrYE4aAt5_EALw_wcB&gclsrc=aw.ds&cid=PAI_GLB00050CN_GLE000BLT8_GLF000ONUF"
              >
                {" "}
                JW Marriott Bengaluru Prestige Golfshire Resort & Spa.
              </a>
            </h2>
            <a href="https://www.marriott.com/en-us/hotels/blrnh-jw-marriott-bengaluru-prestige-golfshire-resort-and-spa/overview/?gclid=Cj0KCQjwsuSzBhCLARIsAIcdLm4TMvFAaITa-utXTPz9QuLBZgi-Ln0ASigYwia1vySNKJuOM_nrYE4aAt5_EALw_wcB&gclsrc=aw.ds&cid=PAI_GLB00050CN_GLE000BLT8_GLF000ONUF">
              {" "}
              <HoverImage
                className="  max-w-full  m-auto lg:h-auto md:h-auto px-5 relative z-1"
                defaultImage={`${process.env.PUBLIC_URL}/images/venu-banner.png`}
                hoverImage={`${process.env.PUBLIC_URL}/images/venu-banner-hover.png`}
                altText=" JW Marriott Bengaluru Prestige Golfshire Resort & Spa."
              />
            </a>

            <h2 className=" subheadingInner mt-10 sm:mt-3">
              A luxurious retreat amidst the serene Nandi Hills, the hotel
              features elegant rooms, world-class amenities, a championship golf
              course and stunning views, providing an idyllic escape for
              relaxation and leisure.
            </h2>
          </div>

          <div id="accommodation" className="flex flex-col items-center">
            <div className="mr-[92px] ml-2 flex flex-col items-start gap-3 self-stretch lg:mx-0 md:mx-0">
              <h3 className="mainHeadingInner text-orange ">ACCOMMODATION</h3>
              <h4 className="subheadingInner pb-8">
                Rooms have been blocked at pre-negotiated rates for registered
                attendees at
              </h4>
            </div>

            <div class="grid grid-cols-2 md:grid-cols-1 gap-14 sm:gap-4 w-full flex items-end">
              <div>
                <img
                  src={`${process.env.PUBLIC_URL}/images/golfshire-resort_new_large.png`}
                  alt=""
                  className="radius-3xl "
                />
              </div>

              <div>
                <h4 className="subheadingInner sm:mt-[20px]">
                  <a
                    className="cursor-pointer"
                    target="_blank"
                    href="https://www.marriott.com/en-us/hotels/blrnh-jw-marriott-bengaluru-prestige-golfshire-resort-and-spa/overview/?gclid=Cj0KCQjwsuSzBhCLARIsAIcdLm4TMvFAaITa-utXTPz9QuLBZgi-Ln0ASigYwia1vySNKJuOM_nrYE4aAt5_EALw_wcB&gclsrc=aw.ds&cid=PAI_GLB00050CN_GLE000BLT8_GLF000ONUF"
                  >
                    {" "}
                    JW Marriott Bengaluru Prestige Golfshire Resort & Spa
                  </a>
                </h4>
                {/* <a target="_blank" href="#" className="btn bookNowBtn">
                  Book Now
                </a> */}
              </div>
            </div>

            <h5 className=" subheadingInner  ml-2 mt-4 self-start font-bold !text-white-a700 lg:ml-0 md:ml-0 ">
              A luxurious retreat amidst the serene Nandi Hills, the hotel
              features elegant rooms, world-class amenities, a championship golf
              course and stunning views, providing an idyllic escape for
              relaxation and leisure. Located just 20 minutes from the airport,
              the hotel is the venue for RIE 2025: RIEMIX.
            </h5>
            <h6 className="text-red w-full mt-16 mb-2 text-2xl fz40 m">
              Rates
            </h6>
            <div class="grid grid-cols-3 sm:grid-cols-1 gap-4 mb-14 w-full flex items-end">
              <div>
                <p className="subheadingInner ">₹ 17,500/- per night + taxes</p>
                <p className="subheadingInner ">Single Occupancy </p>
              </div>
              <div>
                <p className="subheadingInner">₹ 18,000/- per night + taxes</p>
                <p className="subheadingInner">Double Occupancy </p>
              </div>
              <div>
                <p className="subheadingInner">₹ 19,500/- per night + taxes</p>
                <p className="subheadingInner">Triple Occupancy </p>
              </div>
            </div>

            <div class="grid grid-cols-2 md:grid-cols-1 gap-14 sm:gap-4 mt-[50px] sm:mt-[0px] w-full flex items-end">
              <div>
                <img
                  src={`${process.env.PUBLIC_URL}/images/nandi-hills_large.png`}
                  alt=""
                  className="radius-3xl "
                />
              </div>

              <div>
                <h4 className="subheadingInner sm:mt-[20px]">
                  <a
                    className="cursor-pointer"
                    target="_blank"
                    href="https://www.marriott.com/en-us/hotels/blrtx-mulberry-shades-bengaluru-nandi-hills-a-tribute-portfolio-resort/overview/"
                  >
                    Mulberry Shades Bengaluru Nandi Hills
                  </a>
                </h4>
                {/* <a href="#" target="_blank" className="btn bookNowBtn yellow">
                  Book Now
                </a> */}
              </div>
            </div>

            <h5 className=" subheadingInner  ml-2 mt-4 self-start font-bold !text-white-a700 lg:ml-0 md:ml-0 ">
              Nestled between the picturesque Nandi Hills, Mulberry Shades
              Bengaluru Nandi Hills, A Tribute Portfolio Resort by Marriott
              boasts luxury cottages with a focus on sustainability and
              relaxation. The resort is located 30 minutes from the Airport and
              10 minutes from JW Marriott Bengaluru Prestige Golfshire.
            </h5>
            <h6 className="text-orange w-full mt-16 mb-2 text-2xl fz40">
              Rates
            </h6>
            <div class="grid grid-cols-3 sm:grid-cols-1 gap-4 w-full flex items-end">
              <div>
                <p className="subheadingInner ">₹ 13000/- per night + taxes</p>
                <p className="subheadingInner ">Single & Double Occupancy </p>
              </div>
            </div>
          </div>

          <div>
            <h4 className="yellowHeading text-orange">
              Please note the following:
            </h4>

            <ul className="yellowBullet">
              <li>
                Rooms must be booked for the entire duration of the event with
                check-in on 9th January, 2025 and check-out on 12th January,
                2025.
                {/* Please reach out to the hotel directly for bookings beyond
                these dates. */}
              </li>
              <li>A member can book only 1 room, with maximum of 3 guests</li>
              <li>
                Airport transfers and transportation to and from both hotels to
                the event venues will be arranged at no additional cost.
              </li>
              <li>
                Hotel room reservations cannot be canceled but may be
                transferred.{" "}
              </li>
              <li>Hotel availability is on a first come first serve basis.</li>
              <li>
                Once reservations are confirmed, you may reach out to the hotel
                directly for requests regarding room configurations like twin
                beds etc.{" "}
              </li>
              <li>
                Hotel Contact: JW Marriott{" "}
                <a href="mailto:jw.blrnh.reservations@marriott.com">
                  jw.blrnh.reservations@marriott.com
                </a>{" "}
                ; Mulberry Shades{" "}
                <a href="mailto:tp.blrtx.reservations@marriott.com">
                  tp.blrtx.reservations@marriott.com
                </a>
              </li>
            </ul>
          </div>

          <div className="gap-[31px] flex flex-wrap items-center">
            <div className="mx-2  flex flex-col gap-4 self-stretch lg:mx-0 md:mx-0">
              <h2 className="mainHeadingInner text-blue">VISA</h2>
              <h5 className=" subheadingInner  ml-2 mt-4 self-start font-bold !text-white-a700 lg:ml-0 md:ml-0 ">
                Visitors to India must obtain a visa unless their nationality is
                of a visa-exempt country. Nationals of certain countries may
                obtain a visa on arrival or an e-Visa online. Do check with your
                travel agent or the{" "}
                <a
                  href="https://cgi.gov.in/"
                  target="_blank"
                  style={{ color: "#424ea0", display: "inline" }}
                >
                  Indian Consulate website
                </a>{" "}
                for the applicable visa for your nationality.
              </h5>
              <h5 className=" subheadingInner  ml-2 mt-2 self-start font-bold !text-white-a700 lg:ml-0 md:ml-0 ">
                We urge all international travellers seeking a visa for India to
                apply for the same no later than September 30, 2024.
              </h5>
            </div>
          </div>
          <div id="retreat" className="gap-[31px] flex flex-wrap items-center">
            <div className="mx-2  flex flex-col gap-4 self-stretch lg:mx-0 md:mx-0">
              <h2 className="mainHeadingInner ">PLAN YOUR RETREAT</h2>
              <h5 className=" subheadingInner  ml-2 mt-6 self-start font-bold !text-white-a700 lg:ml-0 md:ml-0 ">
                We are excited to offer you the opportunity to plan your Chapter
                or Forum Retreats with us before or after RIE 2025. Stay tuned
                for more…
              </h5>
            </div>

            <div className="mt-[8px] relative mb-[60px] sm:mb-[0px] flex items-end self-stretch sm:flex-col w-full ">
              <div className="flex items-center flex-1 self-end px-10 md:self-end md:px-5 sm:mb-[30px] sm:px-4 flex-col text-center">
                <HoverImage
                  className="object-cover lg:w-auto lg:h-auto  max-w-full"
                  defaultImage={`${process.env.PUBLIC_URL}/images/coorg.png`}
                  hoverImage={`${process.env.PUBLIC_URL}/images/coorg-hover.png`}
                  altText=" Coorg"
                />
                <h4 className="subheadingInner sm:mt-[20px]">Coorg</h4>
              </div>

              <div className="flex items-center flex-1 self-center px-10 md:self-end md:px-5 sm:px-4 flex-col text-center ">
                <HoverImage
                  className=" object-cover lg:w-auto  lg:h-auto md:w-full max-w-full"
                  defaultImage={`${process.env.PUBLIC_URL}/images/pondicherry.png`}
                  hoverImage={`${process.env.PUBLIC_URL}/images/pondicherry-hover.png`}
                  altText="Pondicherry"
                />
                <h4 className="subheadingInner sm:mt-[20px]">Pondicherry</h4>
              </div>
            </div>
            <div className="mt-[8px] relative  flex items-end self-stretch sm:flex-col w-full ">
              <div className="flex items-center flex-1 self-end px-10 md:self-end md:px-5 sm:mb-[20px] sm:px-4 flex-col text-center">
                <HoverImage
                  className="object-cover lg:w-auto lg:h-auto  max-w-full"
                  defaultImage={`${process.env.PUBLIC_URL}/images/hampi.png`}
                  hoverImage={`${process.env.PUBLIC_URL}/images/hampi-hover.png`}
                  altText="Hampi"
                />
                <h4 className="subheadingInner sm:mt-[20px]">Hampi</h4>
              </div>

              <div className="flex items-center flex-1 self-end px-10 md:self-end md:px-5 sm:px-4 flex-col text-center ">
                <HoverImage
                  className="object-cover lg:w-auto  lg:h-auto md:w-full max-w-full"
                  defaultImage={`${process.env.PUBLIC_URL}/images/kabini.png`}
                  hoverImage={`${process.env.PUBLIC_URL}/images/kabini-hover.png`}
                  altText="kabini"
                />
                <h4 className="subheadingInner sm:mt-[20px]">Kabini</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
