import EventSchedule from "components/EventSchedule";
import React, { Suspense } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
export default function EventSlider() {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
        },
      },

      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1.6,
          slidesToScroll: 1,
          dots: true,
          infinite: false,
        },
      },
    ],
  };

  var settingsMultiRow = {
    dots: false,
    slidesPerRow: 3,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 2,
    arrows: false,
    infinite: false,
    rows: 2,

    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          slidesPerRow: 1,
          infinite: false,
        },
      },

      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1.6,
          slidesToScroll: 1,
          dots: true,
          rows: 1,
          slidesPerRow: 1,
        },
      },
    ],
  };
  return (
    <>
      <Suspense fallback={<div>Loading feed...</div>}>
        {/* <Slider {...settings}>
          {eventScheduleList.map((d, index) => (
            <EventSchedule {...d} key={"monthsList" + index} />
          ))}
        </Slider> */}
        <div className="eventDate">9 JANUARY</div>
        <Slider className="redeventSlider" {...settings}>
          <div>
            <div class="scheduleCol mx-0 red ">
              <div class="eventTime">11:00 AM - 11:00 PM</div>
              <div class="eventTitle">Curated MyEO Experiences</div>
            </div>
          </div>
          <div>
            <div class="scheduleCol mx-0 red ">
              <div class="eventTime">2:00 PM - 6:00 PM</div>
              <div class="eventTitle">Registrations</div>
            </div>
          </div>
          <div>
            <div class="scheduleCol mx-0 red ">
              <div class="eventTime">7:00 PM onwards</div>
              <div class="eventTitle">RIEMIX Welcome Dinner</div>
            </div>
          </div>
        </Slider>
        <div className="eventDate ">10 JANUARY</div>

        <Slider className="eventYellowSlider" {...settingsMultiRow}>
          <div>
            <div class="scheduleCol yellow ">
              <div class="eventTime">6:00 AM - 8:00 AM</div>
              <div class="eventTitle">Curated MyEO Experiences</div>
            </div>
          </div>

          <div>
            <div class="scheduleCol yellow ">
              <div class="eventTime">9:00 AM - 11:00 AM</div>
              <div class="eventTitle">Learning Keynote Sessions</div>
            </div>
          </div>
          <div>
            <div class="scheduleCol yellow ">
              <div class="eventTime">11:30 AM - 1:30 PM</div>
              <div class="eventTitle">Learning Keynote Sessions</div>
            </div>
          </div>
          <div>
            <div class="scheduleCol yellow ">
              <div class="eventTime">2:30 PM - 4:30 PM</div>
              <div class="eventTitle">Learning Keynote Sessions</div>
            </div>
          </div>
          <div>
            <div class="scheduleCol yellow ">
              <div class="eventTime">5:00 PM - 6:00 PM</div>
              <div class="eventTitle">Learning Keynote Sessions</div>
            </div>
          </div>
          <div>
            <div class="scheduleCol yellow ">
              <div class="eventTime">7:00 PM ONWARDS</div>
              <div class="eventTitle">Social</div>
            </div>
          </div>
        </Slider>

        <div className="eventDate ">11 JANUARY</div>
        <Slider className="eventGreenSlider" {...settingsMultiRow}>
          <div>
            <div class="scheduleCol green ">
              <div class="eventTime">6:00 AM - 8:00 AM</div>
              <div class="eventTitle">Curated MyEO Experiences</div>
            </div>
          </div>
          <div>
            <div class="scheduleCol green ">
              <div class="eventTime">9:00 AM - 11:00 AM</div>
              <div class="eventTitle">Learning Keynote Sessions</div>
            </div>
          </div>
          <div>
            <div class="scheduleCol green ">
              <div class="eventTime">11:30 AM - 1:30 PM</div>
              <div class="eventTitle">Learning Keynote Sessions</div>
            </div>
          </div>
          <div>
            <div class="scheduleCol green ">
              <div class="eventTime">2:30 PM - 4:30 PM</div>
              <div class="eventTitle">Learning Keynote Sessions</div>
            </div>
          </div>
          <div>
            <div class="scheduleCol green ">
              <div class="eventTime">5:00 PM - 6:00 PM</div>
              <div class="eventTitle">Learning Keynote Sessions</div>
            </div>
          </div>
          <div>
            <div class="scheduleCol green ">
              <div class="eventTime">7:00 PM ONWARDS</div>
              <div class="eventTitle">Social</div>
            </div>
          </div>
        </Slider>
        <div className="eventDate ">12 JANUARY</div>
        <div class="grid grid-cols-3 sm:grid-cols-1 gap-10 w-full">
          <div class="scheduleCol blue ">
            <div class="eventTitle">Departures</div>
          </div>
        </div>
      </Suspense>
    </>
  );
}
